import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class NavItemsService {

  navItemsCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) {
    this.navItemsCollection = fireStore.collection('navItems');
  }

  getNavItems() {
    return this.navItemsCollection.get().pipe(
      map(querySnapshot => {
        return querySnapshot.docs.map(doc => {
          const data = doc.data();
          return { id: doc.id, ...data }; // Include the document ID in the returned object
        });
      })
    );
  }

  addNewNavItem(obj: any) {
    return this.navItemsCollection.add(obj)
  }

  deleteFeature(id: any) {
    return this.navItemsCollection.doc(id).delete();
  }

  updateNavItem(featureId: string, updatedData: any) {
    return this.navItemsCollection.doc(featureId).update(updatedData);
  }

  async addNewSubFeature(docId: string, newArray: any[]) {
    const docRef = this.navItemsCollection.doc(docId);

    try {
      await docRef.update({
        subFeatures: firebase.firestore.FieldValue.arrayUnion(...newArray)
      });
      console.log('Array added successfully!');
    } catch (error) {
      console.error('Error adding array:', error);
      // Handle the error appropriately (e.g., show a user-friendly message)
    }
  }

  deleteSubFeature(docId: string, updatedData: any) {
    return this.navItemsCollection.doc(docId).update(updatedData);
  }

  async updateSubFeature(feature: any, subFeatureName: string, updateObj: any): Promise<any> {
    const docRef = this.navItemsCollection.doc(feature.id);
    try {
      const docSnapshot: any = await docRef.get().toPromise(); // Use toPromise() to get the snapshot directly
      if (docSnapshot.exists) {
        const currentSubFeatures = docSnapshot.data()?.['subFeatures'] || [];
        const subFeatureIndex = currentSubFeatures.findIndex((subFeature: any) => subFeature.subFeatureName === subFeatureName);
        if (subFeatureIndex > -1) {
          // Update only name or isActive if provided in updateObj
          if (updateObj.subFeatureName) {
            currentSubFeatures[subFeatureIndex].subFeatureName = updateObj.subFeatureName;
          }
          if (updateObj.hasOwnProperty('isActive')) { // Use hasOwnProperty to check for isActive explicitly
            currentSubFeatures[subFeatureIndex].isActive = updateObj.isActive;
          }

          currentSubFeatures[subFeatureIndex].lastUpdatedAt = updateObj.lastUpdatedAt;
          currentSubFeatures[subFeatureIndex].lastUpdatedById = updateObj.lastUpdatedById;
          currentSubFeatures[subFeatureIndex].lastUpdatedBy = updateObj.lastUpdatedBy;

          try {
            await docRef.update({ subFeatures: currentSubFeatures });
            console.log('Sub-feature updated successfully!');
            return 4; // Indicate successful update
          } catch (error) {
            console.error('Error updating sub-feature:', error);
            return error;
          }
        } else {
          console.error('Sub-feature not found with name:', subFeatureName);
          return 1;
        }
      } else {
        console.error("Feature not found with ID:", feature.id);
        return 1;
      }
    } catch (error) {
      console.error('Error updating sub-feature:', error);
      return error; // Return the error
    }
  }

  getAccessData(userRole: string): Observable<any[]> {
    return this.fireStore.collection('accessManager', ref => 
      ref.where('accessRoleName', '==', userRole)
    ).valueChanges();
  }
}
