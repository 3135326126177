import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  locationsCollection: AngularFirestoreCollection;
  staffCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore, private http: HttpClient) {
    this.locationsCollection = fireStore.collection('locations');
    this.staffCollection = fireStore.collection('staff');
  }

  async addNewLocation(obj: any) {
    const locationIdQuery = this.fireStore.collection('locations').ref.where('locationId', '==', obj.locationId);
    const locationNameQuery = this.fireStore.collection('locations').ref.where('locationName', '==', obj.locationName);

    const [locationIdSnapshot, locationNameSnapshot] = await Promise.all([locationIdQuery.get(), locationNameQuery.get()]);

    if (!locationIdSnapshot.empty && !locationNameSnapshot.empty) {
      return 3;
    } else if (!locationNameSnapshot.empty) {
      return 2;
    } else if (!locationIdSnapshot.empty) {
      return 1;
    }

    return this.locationsCollection.doc().set(obj);
  }

  async updateLocation(docId: string, updateObj: any, isReSubmit?: boolean) {
    const existingLocationNameQuery = this.fireStore.collection('locations').ref
      .where('locationName', '==', updateObj.locationName)
      .where(firebase.firestore.FieldPath.documentId(), '!=', docId); // Exclude the current document

    const querySnapshot = await existingLocationNameQuery.get();

    if (!querySnapshot.empty) {
      return 2;
    }

    if (isReSubmit) {
      updateObj['rejectionComments'] = firebase.firestore.FieldValue.delete(); // Mark rejectionComments for deletion
    }

    await this.locationsCollection.doc(docId).update(updateObj);
    return 4;
  }

  async addNewLocality(docId: string, newArray: any[]) {
    const docRef = this.locationsCollection.doc(docId);

    try {
      await docRef.update({
        localities: firebase.firestore.FieldValue.arrayUnion(...newArray)
      });
      console.log('Array added successfully!');
    } catch (error) {
      console.error('Error adding array:', error);
      // Handle the error appropriately (e.g., show a user-friendly message)
    }
  }

  async updateLocality(locationDocId: string, localityId: string, updateObj: any, isReSubmit?: boolean) {
    const docRef = this.locationsCollection.doc(locationDocId);

    try {
      docRef.get().subscribe(async (docSnapshot) => {
        if (docSnapshot.exists) {
          const currentLocalities = docSnapshot.data()?.['localities'] || [];

          const localityIndex = currentLocalities.findIndex((locality: any) => locality.localityId === localityId);

          if (localityIndex > -1) {
            // Update the locality at the found index
            currentLocalities[localityIndex] = { ...currentLocalities[localityIndex], ...updateObj };

            // If isReSubmit is true, remove rejectionComments
            if (isReSubmit) {
              delete currentLocalities[localityIndex].rejectionComments;
            }

            // Update the Firestore document with the modified localities array
            try {
              await docRef.update({ localities: currentLocalities });
              console.log('Locality updated successfully!');
              return 4; // Indicate successful update
            } catch (error) {
              console.error('Error updating locality:', error);
              return error;
            }
          } else {
            console.error('Locality not found with ID:', localityId);
            return 1;
          }
        } else {
          console.error("Location not found with ID:", locationDocId);
          return 1;
        }
      }, (error) => {
        console.error('Error fetching document:', error);
      });
    } catch (error) {
      console.error('Error updating locality:', error);
    }
  }

  getLocationData() {
    return this.locationsCollection.get().pipe(
      map(querySnapshot => {
        return querySnapshot.docs.map(doc => {
          const data = doc.data();
          return { id: doc.id, ...data }; // Include the document ID in the returned object
        });
      })
    );
  }

  deleteLocation(docId: string) {
    return this.locationsCollection.doc(docId).delete();
  }

  deleteLocality(docId: string, updatedData: any) {
    return this.locationsCollection.doc(docId).update(updatedData);
  }

  getActiveLocation(locationId: string | undefined) {
    return this.locationsCollection.doc(locationId).get();
  }

  async updateLocationStatus(locationDocId: string, updateData: any, isUpdateRejected?: boolean) {
    const docRef = this.locationsCollection.doc(locationDocId);

    try {
      const docSnapshot = await docRef.get().toPromise(); // Convert the observable to a promise

      if (docSnapshot?.exists) { // Check if the document exists
        const docData = docSnapshot.data();

        // Check if the document has the 'newLocationName' field
        if (docData && docData['newLocationName'] && !isUpdateRejected) {
          // Replace the value of 'locationName' with 'newLocationName'
          updateData.locationName = docData['newLocationName'];

          // Mark 'newLocationName' for deletion
          updateData.newLocationName = firebase.firestore.FieldValue.delete();
        }
        if (docData && isUpdateRejected) {
          updateData.newLocationName = firebase.firestore.FieldValue.delete();
          updateData.status = 'active'
          delete updateData['rejectionComments'];
        }
      }

      // Update the document with the modified data
      await docRef.update(updateData);
      console.log('Location status and name updated successfully!');
      return 4; // Indicate successful update
    } catch (error) {
      console.error('Error updating location status:', error);
      throw error; // Handle the error appropriately
    }
  }

  async updateLocalityStatus(locationId: string, localityId: string, status: string, lastUpdatedAt: any, lastUpdatedById: any, lastUpdatedBy: any, rejectionComments?: string, isUpdateRejected?: boolean) {
    const locationDocRef = this.locationsCollection.doc(locationId);

    try {
      const docSnapshot = await locationDocRef.get().toPromise();

      if (docSnapshot?.exists) {
        const locationData: any = docSnapshot.data();

        if (locationData.localities) {
          const updatedLocalities = locationData.localities.map((locality: any) => {
            if (locality.localityId === localityId) {
              // Check if newLocalityName exists
              if (locality.newLocalityName) {
                if (isUpdateRejected) {
                  delete locality.newLocalityName;
                  locality.status = 'active'; // Set status to active regardless of the 'status' parameter
                  delete locality.rejectionComments;
                } else {
                  locality.localityName = locality.newLocalityName;
                  delete locality.newLocalityName;
                }
              }

              // Update other fields as needed
              if (isUpdateRejected) {
                return {
                  ...locality,
                  lastUpdatedAt: lastUpdatedAt,
                  lastUpdatedById: lastUpdatedById,
                  lastUpdatedBy: lastUpdatedBy,
                  status: 'active',
                }
              } else {
                if (rejectionComments) {
                  return {
                    ...locality,
                    status: status, // Use the provided 'status' if not rejected
                    lastUpdatedAt: lastUpdatedAt,
                    lastUpdatedById: lastUpdatedById,
                    lastUpdatedBy: lastUpdatedBy,
                    rejectionComments: status === 'rejected' ? rejectionComments || '' : locality.rejectionComments
                  };
                } else {
                  return {
                    ...locality,
                    lastUpdatedAt: lastUpdatedAt,
                    lastUpdatedById: lastUpdatedById,
                    lastUpdatedBy: lastUpdatedBy,
                    status: status, // Use the provided 'status' if not rejected
                  };
                }
              }
            }
            return locality;
          });

          // Update the document with the modified localities array
          await locationDocRef.update({ localities: updatedLocalities });
          console.log('Locality status and name updated successfully!');
          return 4; // Indicate successful update
        } else {
          throw new Error('Localities array does not exist in the document.');
        }
      } else {
        throw new Error('Document does not exist.');
      }
    } catch (error) {
      console.error('Error updating locality status:', error);
      throw error;
    }
  }

  sendEmail(to: string[], subject: string, htmlContent: string): Observable<any> { // Pass HTML content directly
    const body = {
      to,
      subject,
      html: htmlContent, // Use the provided HTML content
      cc: ['abhinav.chinta@adev.co.in', 'karish.md@adev.co.in']
    };

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post('https://asia-south1-aahaar-qa.cloudfunctions.net/sendEmail', body, httpOptions);
  }

  getUserEmail(userId: any) {
    return this.staffCollection.doc(userId).get();
  }
}
