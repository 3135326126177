<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Delivery Partner'"></app-title-bar>
    </div>
    <!-- <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search" />
            </p-iconField>
        </div>
        <div class="d-flex gap-2">
            <div class="p-date d-flex">
                <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true"
                    inputId="icondisplay" />
            </div>
        </div>
    </div> -->
    <div class="bodySection">
        <p-table [value]="partners" dataKey="partnerId" [expandedRowKeys]="expandedRows"
            (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th>Delivery Partner ID</th>
                    <th>Delivery Partner Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-partner let-expanded="expanded">
                <tr>
                    <td>{{partner.deliveryPartnerId}}</td>
                    <td>{{partner.basicDetails.firstName +' '+ partner.basicDetails.lastName}}</td>
                    <td class="d-flex gap-3 align-items-center">
                        <span class="statusPill" [ngClass]="{
                        'activePill':  partner.status  === 'active',
                        'pendingPill':  partner.status  === 'pending',
                        'rejectedPill':  partner.status  === 'rejected',
                        'disabledPill':  partner.status  === 'disabled'
                        }">{{ partner.status }}</span>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-info-circle actionIconBtn" (click)="showDialog(partner)"></span>
                            <span class="pi pi-pen-to-square actionIconBtn"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog [modal]="true" [(visible)]="visible" [style]="{ width: '30rem' , 'height':'30rem' }">
    <div class="d-flex flex-column gap-2">
        <div class="chipSection d-flex justify-content-between ">
            <div class="d-flex gap-3">
                <span class="basicDetailsChip" (click)="toggleBasicDetails()">Basic Details</span>
                <span class="IdProofChip" (click)="toggleIdProofs()">Id Proofs</span>
                <span class="VehicalChip" (click)="toggleVehicleProofs()">Vehicle Proofs</span>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <span class="pi pi-times" (click)="DontshowDialog()"></span>
            </div>
        </div>
        <div class="detailsInfo">
            <p-table [value]="[selectedPartner]" *ngIf="activeTable === 'basic'" [expandedRowKeys]="expandedRows"
                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Label</th>
                        <th>Value</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                    <tr>
                        <td>Profile</td>
                        <td>
                            <div style="width: 150px; height: 150px; border-radius: 10px;">
                                <img [src]="partner.basicDetails?.profileImageUrl" alt="Profile Image"
                                    style="width: 100%; height: 100%; object-fit: contain;">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>First Name</td>
                        <td>{{ partner.basicDetails?.firstName }}</td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>{{ partner.basicDetails?.lastName }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ partner.basicDetails?.email }}</td>
                    </tr>
                    <tr>
                        <td>Mobile Number</td>
                        <td>{{ partner.basicDetails?.mobileNumber }}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{{ partner.basicDetails?.address }}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{{ partner.basicDetails?.gender }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="accordionField" *ngIf="activeTable === 'idProofs'">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Aadhaar Data</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Label</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>Profile</td>
                                        <td>
                                          <div style="width: 200px; height: 200px; border-radius: 10px;">
                                            <img [src]="'data:image/jpeg;base64,' + partner.aadhaarData.data.profile_image" alt="Profile Image" style="width: 100%; height: 100%; object-fit: contain;"> 
                                          </div>
                                        </td>
                                      </tr>
                                    <tr>
                                        <td>Aadhaar Number</td>
                                        <td>{{ partner.aadhaarData.data.aadhaar_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{ partner.aadhaarData.data.full_name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Birth</td>
                                        <td>{{ partner.aadhaarData.data.dob }}</td>
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>{{ partner.aadhaarData.data.gender }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">PAN Data</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Label</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>PAN Number</td>
                                        <td>{{ partner.panData.data.pan_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{ partner.panData.data.full_name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Category</td>
                                        <td>{{ partner.panData.data.category }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div class="accordionField" *ngIf="activeTable === 'vehicleProofs'">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Driving License Details</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>Profile</td>
                                        <td>
                                          <div style="width: 200px; height: 200px; border-radius: 10px;">
                                            <img [src]="'data:image/jpeg;base64,' + partner.drivingLicenseData.data.profile_image" alt="Profile Image" style="width: 100%; height: 100%; object-fit: contain;"> 
                                          </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{partner.drivingLicenseData.data.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>DOB</td>
                                        <td>{{partner.drivingLicenseData.data.dob}}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Status</td>
                                        <td>{{partner.drivingLicenseData.data.current_status}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Issue</td>
                                        <td>{{partner.drivingLicenseData.data.doi}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Expiry</td>
                                        <td>{{partner.drivingLicenseData.data.doe}}</td>
                                    </tr>
                                    <tr>
                                        <td>License Number</td>
                                        <td>{{partner.drivingLicenseData.data.license_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>OLA Code</td>
                                        <td>{{partner.drivingLicenseData.data.ola_code}}</td>
                                    </tr>
                                    <tr>
                                        <td>OLA Name</td>
                                        <td>{{partner.drivingLicenseData.data.ola_name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent Address</td>
                                        <td>{{partner.drivingLicenseData.data.permanent_address}}</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle Classes</td>
                                        <td>{{ partner.drivingLicenseData.data.vehicle_classes.join(', ') }}</td>
                                      </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">RC Details</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>Owner Name</td>
                                        <td>{{partner.vehicleData.data.owner_name}}</td>
                                    </tr>
                                    <tr>
                                        <td>RC Number</td>
                                        <td>{{partner.vehicleData.data.rc_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>RC Status</td>
                                        <td>{{partner.vehicleData.data.rc_status}}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Company</td>
                                        <td>{{partner.vehicleData.data.insurance_company}}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Policy Number</td>
                                        <td>{{partner.vehicleData.data.insurance_policy_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Upto</td>
                                        <td>{{partner.vehicleData.data.insurance_upto}}</td>
                                    </tr>
                                    <tr>
                                        <td>Manufacturer</td>
                                        <td>{{partner.vehicleData.data.maker_description}}</td>
                                    </tr>
                                    <tr>
                                        <td>Model</td>
                                        <td>{{partner.vehicleData.data.maker_model}}</td>
                                    </tr>
                                    <tr>
                                        <td>Chasi Number</td>
                                        <td>{{partner.vehicleData.data.vehicle_chasi_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Engine Number</td>
                                        <td>{{partner.vehicleData.data.vehicle_engine_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent Address</td>
                                        <td>{{partner.vehicleData.data.permanent_address}}</td>
                                    </tr>
                                    <tr>
                                        <td>Present Address</td>
                                        <td>{{partner.vehicleData.data.present_address}}</td>
                                    </tr>
                                    <tr>
                                        <td>Registered At</td>
                                        <td>{{partner.vehicleData.data.registered_at}}</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle Category</td>
                                        <td>{{partner.vehicleData.data.vehicle_category}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
        <!--
            <p-table [value]="vehicleProofs" *ngIf="activeTable === 'vehicleProofs'"  [expandedRowKeys]="expandedRows"
                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Driving Lincense</th>
                        <th>RC </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-vehicleProofs let-expanded="expanded">
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
        </div> -->
    </div>
</p-dialog>