import { Component, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaffService } from '../../services/staff.service';
import { ToastService } from '../../shared/toast.service';
import { GlobalDataService } from '../../services/global-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  activeIndices: { [key: string]: boolean } = {}; // Store active indices using navItem.name as key
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showCompleteProfileModal: boolean = false;
  staffRegistrationForm: FormGroup;
  user: any;
  userData: any;

  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder,
    private staffService: StaffService, private toastService: ToastService, private globalDataService: GlobalDataService) {
    this.getScreenSize();
    this.staffRegistrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });
    this.getStaffData();
  }

  getStaffData() {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
          if (!res.data().firstName) {
            this.toggleCompleteProfile();
          } else {
            this.userData = res.data();
            this.staffService.staffDetails = this.userData;
          }
          // this.showSpinner = false;
        })
      } else {
        // User not logged in or has just logged out.
      }
    });
  }

  navigateToHome() {

  }

  onTabOpen(navItemName: string) {
    this.activeIndices[navItemName] = true;
  }

  onTabClose(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  updateRoute(mainRoute: string, subRoute: string) {

    this.router.navigate(['home', mainRoute.toLowerCase()], {
      queryParams: { action: subRoute.toLowerCase() }
    });
  }

  toggleCompleteProfile() {
    this.showCompleteProfileModal = !this.showCompleteProfileModal;
  }

  updateStaffDetails() {
    var obj = {
      firstName: this.staffRegistrationForm?.value.firstName,
      lastName: this.staffRegistrationForm?.value.lastName,
      mobile: this.staffRegistrationForm?.value.mobileNumber
    }
    this.staffService.completeStaffRegistration(this.user.uid, obj).then((res: any) => {
      this.toastService.showSuccess("Registration completed successfully");
      this.getStaffData();
      this.showCompleteProfileModal = false;
    }, (err: any) => {
      // this.showSpinner = false;
      this.toastService.showError(`Error completing registration. ${err}`)
    })
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
