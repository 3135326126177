import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalDataService } from '../../services/global-data.service';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../shared/toast.service';
import { Router } from '@angular/router';
import { DiscountsService } from '../../services/discounts.service';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrl: './discounts.component.scss'
})
export class DiscountsComponent {

  discounts: any[] = [];
  showAddAccessRoleModal: boolean = false;
  showSpinner: boolean = false;
  addDiscountForm: FormGroup;
  user: any
  userData: any

  constructor(private fb: FormBuilder, private globalDataService: GlobalDataService, private authService: AuthService, private toastService: ToastService, private router: Router, private discountsService: DiscountsService) {
    this.addDiscountForm = this.fb.group({
      discountCode: ['', Validators.required],
      discountDescription: ['', Validators.required],
    });
    if(this.globalDataService.checkAccess('Discounts')) {
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.user = user;
          this.userData = this.globalDataService.getUserData();
          if (!this.userData) {
            this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
              this.userData = res.data();
              this.globalDataService.setUserData(this.userData);
            })
          }
          this.getDiscounts();
        } else {
          console.warn("User not logged in");
          // User not logged in or has just logged out.
        }
      });
    } else {
      this.router.navigate(['/home']);
      this.toastService.showError("You are not authorised to access this feature")
    }
  }

  toggleAddDiscountModal() {
    this.showAddAccessRoleModal = true;
  }

  addDiscount() {
    this.showSpinner = true;
    var obj = {
      discountCode: this.addDiscountForm.value.discountCode,
      discountDescription: this.addDiscountForm.value.discountDescription,
      lastUpdatedAt: new Date().getTime(),
      lastUpdatedById: this.user.uid,
      lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
      createdAt: new Date().getTime(),
      createdById: this.user.uid,
      createdBy: this.userData.firstName + ' ' + this.userData.lastName,
    };
    this.discountsService.addDiscounts(obj).then((res: any) => {
      this.toastService.showSuccess("Access group addedd successfully")
      this.showSpinner = false;
      this.showAddAccessRoleModal = false;
      this.getDiscounts();
    }, err => {
      this.showSpinner = false;
      this.toastService.showError(err)
    })
  }

  getDiscounts() {
    this.discounts = [];
    this.discountsService.getActiveDiscounts().subscribe((res: any) => {
      res.forEach((discount: any) => {
        this.discounts.push(discount.data())
      });
    })
  }
}
