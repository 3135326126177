<div class="mainContainer h-100 w-100">
    <div class="titleSection d-flex justify-content-between align-items-center">
        <span>
            {{activeNav}} <span *ngIf="activeAction">| {{activeAction}}</span>
        </span>
        <div class="notificationIconHolder" 
            (click)="notificationPanel.toggle($event)"> 
            <i class="pi pi-bell text-3xl" pBadge [value]="notifications.length"></i>
        </div>
    </div>

    <p-overlayPanel #notificationPanel [showCloseIcon]="false">
        <ng-template pTemplate>
            <div *ngIf="notifications.length > 0; else noNotifications">
                <div *ngFor="let notification of notifications" class="notificationItem">
                    <div class="d-flex align-items-center gap-3 flex-column" [ngClass]="{'successNotification': notification.severity == 'success'}">
                        <div class="d-flex align-items-center gap-3">
                            <i *ngIf="notification.severity === 'success'" class="pi pi-check-circle mr-2" style="color: green; font-size: larger;"></i>
                            <div class="d-flex flex-column"> 
                                <span class="notificationTitle">{{ notification.notificationTitle }}</span>
                                <span class="notificationDescription text-muted">{{ notification.notificationDescription }}</span>
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <div class="notificationTime">
                                {{ notification.notificationTime| date:'dd MMM yyyy, hh:mm a' }}
                            </div>
                            <div class="actionPanel">
                                <span (click)="clearNotification(notification.id, $event, notificationPanel)">Clear</span>                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noNotifications> 
                <div class="emptyMessage">No new notifications</div>
            </ng-template>
        </ng-template>
    </p-overlayPanel>
</div>