import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ApplicationsService } from '../../services/applications.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss'
})
export class CustomersComponent {
  activeAction: string | null = null;
  details: any[] = [];
  idProofs: any[] = [];
  customers: any[] = [];
  vehicleProofs: any[] = [];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  visible: boolean = false;
  activeItem: MenuItem | undefined;
  tabMenuItems: any[] = [];

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

  showDialog() {
    this.visible = true;
  }
  DontshowDialog() {
    this.visible = false;
  }

  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService) {
    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Order History'},
      { label: 'Wallet History'},
    ];
    this.activeItem = this.tabMenuItems[0]
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action']
        ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
        : undefined;
    });
    this.customers = [
      {
        customerId: '1234565',
        customerName: 'Uma Keerthi',
        customerMobileNo: '8978209262' ,
        customerRepeatOrders: '5th Time',
        customerAddress: 'Amudhala Appala Swamy Colony,Eluru',
      },
    ];
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.restaurantId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.restaurantId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  }
}
