import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class DeliveryPartnerService {

  deliveryPartnersCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) {
    this.deliveryPartnersCollection = fireStore.collection('deliveryPartners');
  }

  getDeliveryPartners() {
    return this.deliveryPartnersCollection.get();
  }
}
