import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavItemsService } from '../../services/nav-items.service';
import { AccessManagerService } from '../../services/access-manager.service';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { GlobalDataService } from '../../services/global-data.service';
import { ToastService } from '../../shared/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-access-manager',
  templateUrl: './access-manager.component.html',
  styleUrl: './access-manager.component.scss'
})
export class AccessManagerComponent {

  showSpinner: boolean = false
  showAddAccessRoleModal: boolean = false
  addAccessRoleForm: FormGroup;
  navItems: any[] = [];
  accessRoles: any[] = [];
  user: any;
  userData: any;

  constructor(private fb: FormBuilder, private navItemsService: NavItemsService, private accessManagerService: AccessManagerService,
    private globalDataService: GlobalDataService, private authService: AuthService, private toastService: ToastService, private router: Router
  ) {
    this.addAccessRoleForm = this.fb.group({
      accessRoleName: ['', Validators.required],
      accessGroupList: ['', Validators.required],
    });
    if(this.globalDataService.checkAccess('Access Manager')) {
      this.getAccessGroupList()
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.user = user;
          this.userData = this.globalDataService.getUserData();
          if (!this.userData) {
            this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
              this.userData = res.data();
              this.globalDataService.setUserData(this.userData);
            })
          }
        } else {
          console.warn("User not logged in");
          // User not logged in or has just logged out.
        }
      });
      this.navItemsService.getNavItems().subscribe((res: any) => {
        res.forEach((navItem: any) => {
          this.navItems.push(navItem)
        })
      })
    } else {
      this.router.navigate(['/home']);
      this.toastService.showError("You are not authorised to access this feature")
    }
  }

  getAccessGroupList() {
    this.accessRoles = [];
    this.accessManagerService.getAccessGroupList().subscribe((res: any) => {
      res.forEach((accessRole: any) => {
        this.accessRoles.push(accessRole)
      });
    })
  }

  toggleAddAccessRoleModal() {
    this.showAddAccessRoleModal = !this.showAddAccessRoleModal
  }

  addNewAccessRole() {
    this.showSpinner = true;
    var obj = {
      accessRoleName: this.addAccessRoleForm.value.accessRoleName,
      accessGroupList: this.addAccessRoleForm.value.accessGroupList,
      lastUpdatedAt: new Date().getTime(),
      lastUpdatedById: this.user.uid,
      lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
      createdAt: new Date().getTime(),
      createdById: this.user.uid,
      createdBy: this.userData.firstName + ' ' + this.userData.lastName,
    };
    this.accessManagerService.addAccessGroup(obj).then((res: any) => {
      this.toastService.showSuccess("Access group addedd successfully")
      this.showSpinner = false;
      this.showAddAccessRoleModal = false;
      this.getAccessGroupList();
    }, err => {
      this.showSpinner = false;
      this.toastService.showError(err)
    })
  }

  getFeatureNames(accessGroupList: any[]): string {
    return accessGroupList.map(item => item.featureName).join(', ');
  }
}
