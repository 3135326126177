import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss'
})
export class ApplicationsComponent {

  activeAction: string | null = null;
  openApplications: any;
  applicationOwners: any[] = [];
  expandedRows: any = {};
  showSpinner: boolean = false;
  dataLoading: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService, private toastService: ToastService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['action'] == 'all') {
        this.getApplications()
      } else if (params['action'] == 'open') {
        // Handle 'all' param
      }
    });
  }

  getApplications(action?: string) {
    this.dataLoading = true;
    this.openApplications = []
    this.applicationOwners = []
    this.applicationService.getOpenApplicationOwners().subscribe((res: any) => {
      this.openApplications = res.data().openApplications;
      this.openApplications.forEach((docId: any) => {
        this.applicationService.getOpenApplicationDetails(docId).then((res: any) => {
          this.applicationOwners.push(res)
        })
      });
      this.dataLoading = false;
    })
    this.cdr.detectChanges();
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.ownerId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.ownerId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  approveOutlet(application: any) {
    this.showSpinner = true;
    this.applicationService.approveOutletApplication('Eluru', 'Sanivarapupeta', application).then((res) => {
      this.toastService.showSuccess("Application approved successfully")
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      this.toastService.showError(err);
    })
  }

  deleteApplication(owner: any, application: any) {
    this.showSpinner = true;
    this.applicationService.deleteApplication(owner, application).then((res: any) => {
      this.showSpinner = false;
      this.toastService.showSuccess("Application deleted successfully");
      this.getApplications();
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", err)
    })
  }

  deleteOwner(owner: any) {
    this.showSpinner = true;
    this.applicationService.deleteOwner(owner).then((res: any) => {
      this.showSpinner = false;
      this.toastService.showSuccess("Owner details deleted successfully");
      this.getApplications();
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", err)
    })
  }
}
