import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from, map, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private firebaseAuth: AngularFireAuth, private router: Router, private firestore: AngularFirestore) {}

  canActivate(): Observable<boolean> {
    return this.firebaseAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return from(this.firestore.collection('staff').doc(user.uid).ref.get()).pipe(
            map(docSnapshot => {
              if (docSnapshot.exists) {
                const data: any = docSnapshot.data();
                // Check if `isActive` field exists and is true
                if(data?.isActive) {
                  return true;
                } else {
                  this.router.navigate(['/login', { state: 'disabled' }]);
                }
                // return data?.isActive === true;
              }
              return false; // Return false if document doesn't exist
            }),
            catchError(error => {
              console.error('Firestore error:', error);
              return of(false); // Return false in case of an error
            })
          );
        } else {
          this.router.navigate(['/login', { state: 'restrict' }]);
          return of(false);
        }
      })
    );
  }
}