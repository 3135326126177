import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  userData: any;

  setUserData(data: any) {
    this.userData = data;
  }

  getUserData() {
    return this.userData;
  }

  checkAccess(featureName: string): boolean {
    const accessGroupList = JSON.parse(localStorage.getItem('accessGroupList') || '[]');
    if (!accessGroupList) {
      return false; // Or handle the case where accessGroupList is not available
    }

    return accessGroupList.some((item: any) => item.featureName === featureName); // Direct comparison
  }
}
