<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Logs'"></app-title-bar>
    </div>
    <div class="bodySection">
        <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <div class="cardHolder" *ngIf="activeItem!.label == 'Log History'">
            <div class="sortSection d-flex justify-content-between">
                <div class="searchSection d-flex">
                    <p-iconField iconPosition="left">
                        <p-inputIcon styleClass="pi pi-search" />
                        <input type="text" pInputText placeholder="Search" />
                    </p-iconField>
                </div>
                <div class="d-flex gap-2">
                    <div class="p-date d-flex">
                        <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true"
                            inputId="icondisplay" />
                    </div>
                </div>
            </div>
            <div class="alertInfo text-muted">
                The system automatically clears the logs at 00:00 AM on the 1st of each month.
            </div>
            <p-table [value]="logs">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 10%;">Log Id</th>
                        <th style="width: 12%">Feature Name</th>
                        <th style="width: 10%;">Log Type</th>
                        <th style="width: 18%;">Timestamp</th>
                        <th style="width: 30%;">Summary</th>
                        <th style="width: 15%; min-width: 175px">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-logs let-expanded="expanded">
                    <tr>
                        <td>{{logs.logId}}</td>
                        <td>{{logs.featureName}}</td>
                        <td>{{logs.logType}}</td>
                        <td>{{logs.timeStamp | date:'dd MMM yyyy, hh:mm a' }}</td>
                        <td>{{logs.summary}}</td>
                        <td>
                            <div class="d-flex gap-2 align-items-center">
                                <button class="btn btn-link p-0 signUpBtn">More Details</button>
                                <span class="pi pi-trash actionIconBtn"></span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="cardHolder" *ngIf="activeItem!.label == 'Log Triggers'">
            <div class="addBtn mb-3 d-flex justify-content-end">
                <span class="addTriggerBtn">
                    <p-button label="Add Trigger" icon="pi pi-plus" (onClick)="toggleAddTriggerModal()" />
                </span>
            </div>
            <p-table [value]="logTriggers">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Trigger Id</th>
                        <th>Trigger Feature</th>
                        <th>Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-trigger>
                    <tr>
                        <td>{{ trigger.logTriggersId }}</td>
                        <td>
                           {{trigger.logTriggersFeature}}
                        </td>
                        <td>
                            <div class="d-flex gap-2">
                                <span class="pi pi-plus actionIconBtn" (click)="toggleAddLogTriggerModal(log)"></span>
                                <span class="pi pi-trash actionIconBtn" (click)="toggleDeleteTriggerConfirmation(log)"></span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-dialog header="Add Log Trigger" class="TriggerModal" [modal]="true" [(visible)]="showAddTriggerModal"
    [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <form [formGroup]="addTriggerForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <label for="triggerId">Trigger ID</label>
                <input pInputText id="triggerId" formControlName="triggerId" />
            </div>
            <label for="triggerFeature">Trigger Feature</label>
            <div class="p-field ">
                <p-dropdown id="triggerFeature" [options]="features" [(ngModel)]="selectedFeature" appendTo="body"
                    placeholder="Features"></p-dropdown>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2 w-100">
        <p-button label="Continue" />
    </div>
</p-dialog>

<p-dialog header="Add Log Trigger"  class="updateModal" [modal]="true"
    (onHide)="toggleAddLogTriggerModal(null, true)" [(visible)]="showAddLogTriggerModal" [draggable]="false"
    [closable]="true" [style]="{ width: '30vw' }">
    <form [formGroup]="addTriggerForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <label for="action">Actions</label>
                <input pInputText id="action" formControlName="action" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="summary">Summary</label>
                <textarea rows="3" cols="30" pInputTextarea formControlName="summary" placeholder="Order ID '${1}' canceled by staff member '${2}' due to customer request."></textarea>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2 w-100">
        <p-button label="Continue" />
    </div>
</p-dialog>

<p-dialog header="Confirm" class="signUpModal" [modal]="true" [(visible)]="showDeleteConfirmationModal"
    [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete .
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger"/>
    </div>
</p-dialog>