import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';
import { MenuItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Features {
  name: string;
}

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss'
})
export class LogsComponent {
  activeAction: string | null = null;
  selectedDate: Date | null = null;
  value: string = '';
  logs: any[] =[];
  log: any[]=[];
  location: any[]=[];
  logTriggers: any[] =[];
  tabMenuItems: any[] = [];
  activeItem: MenuItem | undefined;
  showAddTriggerModal: boolean = false;
  addTriggerForm: FormGroup;
  selectedTrigger: any;
  features: any[] | undefined;
  selectedFeature: Features[] | undefined;
  isUpdateTrigger: boolean = false;
  showAddLogTriggerModal: boolean = false;
  showDeleteConfirmationModal : boolean =false;

  toggleAddLogTriggerModal(log?: any, isClose?: boolean) {
    if (isClose) {
      this.showAddLogTriggerModal = false;
      this.addTriggerForm.reset();
      this.addTriggerForm.controls['summary'].enable();
    } else {
      this.showAddLogTriggerModal = !this.showAddLogTriggerModal;
    }
    if (!this.showAddLogTriggerModal) {
      this.addTriggerForm.reset()
      this.isUpdateTrigger = false;
      this.selectedTrigger = null;
    } else {
      if (log) {
        this.isUpdateTrigger = true;
        this.selectedTrigger = log;
      } else {
        this.isUpdateTrigger = false;
        this.selectedTrigger = null;
      }
    }
  }

  toggleDeleteTriggerConfirmation(log: any) {
    this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
    if (this.showDeleteConfirmationModal) {
      this.selectedTrigger = log;
    } else {
      this.selectedTrigger = null;
    }
  }

  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService, private fb: FormBuilder) {
    
    this.addTriggerForm = this.fb.group({
      triggerId: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]],
    });

    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Log History'},
      { label: 'Log Triggers'},
    ];
    this.activeItem = this.tabMenuItems[0]
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });

    this.features = [
     "staff","orders","outlets"
  ];

  this.logTriggers = [
    {
      logTriggersId: '098',
      logTriggersFeature: 'order',
    },
  ];

    this.logs = [
      {
        logId: '098765',
        featureName: 'Order',
        logType: 'Cancel',
        timeStamp: 1727296374007,
        summary: 'Order ID 78914 canceled by staff member Emily Roberts due to customer request.',
      },
      {
        logId: '078765',
        featureName: 'Outlet',
        logType: 'Update',
        timeStamp: 1727296374007,
        summary: 'Status of outlet Spicy Corner updated to Active by  Sarah .',
      },
    ];
  }

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

  toggleAddTriggerModal() {
    this.showAddTriggerModal = !this.showAddTriggerModal;
  }

}
