import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessManagerService {

  accessManagerCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) {
    this.accessManagerCollection = fireStore.collection('accessManager');
  }

  getAccessGroupList() {
    return this.accessManagerCollection.get().pipe(
      map(querySnapshot => {
        return querySnapshot.docs.map(doc => {
          const data = doc.data();
          return { id: doc.id, ...data };
        });
      })
    );
  }

  addAccessGroup(obj: any) {
    return this.accessManagerCollection.add(obj)
  }
}
