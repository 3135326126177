import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './shared/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { AvatarModule } from 'primeng/avatar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'primeng/accordion';
import { ApplicationsComponent } from './components/applications/applications.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { OutletsComponent } from './components/outlets/outlets.component';
import { OrdersComponent } from './components/orders/orders.component';
import { StaffComponent } from './components/staff/staff.component';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { InputGroupModule } from 'primeng/inputgroup';
import { CalendarModule } from 'primeng/calendar';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { FloatLabelModule } from 'primeng/floatlabel';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LocationsComponent } from './components/locations/locations.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { RatingModule } from 'primeng/rating';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { LogsComponent } from './components/logs/logs.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { NavItemsComponent } from './components/nav-items/nav-items.component';
import { AccessManagerComponent } from './components/access-manager/access-manager.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CustomersComponent } from './components/customers/customers.component';
import { DeliveryPartnerComponent } from './components/delivery-partner/delivery-partner.component';
import { DiscountsComponent } from './components/discounts/discounts.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    ApplicationsComponent,
    ComplaintsComponent,
    OutletsComponent,
    OrdersComponent,
    StaffComponent,
    SideBarComponent,
    SpinnerComponent,
    LocationsComponent,
    TitleBarComponent,
    LogsComponent,
    NavItemsComponent,
    AccessManagerComponent,
    CustomersComponent,
    DeliveryPartnerComponent,
    DiscountsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    PanelMenuModule,
    BrowserAnimationsModule,
    AccordionModule,
    TableModule,
    ButtonModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,
    ToastModule,
    InputGroupModule,
    CalendarModule,
    InputIconModule,
    IconFieldModule,
    RatingModule,
    FloatLabelModule,
    PasswordModule,
    CardModule,
    BadgeModule,
    OverlayPanelModule,
    InputTextareaModule,
    TabMenuModule,
    MultiSelectModule
  ],
  providers: [AuthGuard, MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
