import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';
import { DeliveryPartnerService } from '../../services/delivery-partner.service';
import { ToastService } from '../../shared/toast.service';


@Component({
  selector: 'app-delivery-partner',
  templateUrl: './delivery-partner.component.html',
  styleUrl: './delivery-partner.component.scss'
})
export class DeliveryPartnerComponent {

  activeAction: string | null = null;
  details: any[] = [];
  idProofs: any[] = [];
  partners: any[] =[];
  vehicleProofs: any[] = [];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  visible: boolean = false;
  activeTable: 'basic' | 'idProofs' | 'vehicleProofs' | null = null;
  selectedPartner: any;

  constructor(private activatedRoute: ActivatedRoute, private deliveryPartnerService: DeliveryPartnerService, private toastService: ToastService) {
    this.deliveryPartnerService.getDeliveryPartners().subscribe((res) => {
      res.forEach(deliveryPartner => {
        this.partners.push(deliveryPartner.data());
      });
    }, (err) => {
      this.toastService.showError(err)
    })
  }

  toggleVehicleProofs() {
    this.activeTable = this.activeTable === 'vehicleProofs' ? null : 'vehicleProofs'; 
  }

  toggleBasicDetails() {
    this.activeTable = this.activeTable === 'basic' ? null : 'basic';
  }

  toggleIdProofs() {
    this.activeTable = this.activeTable === 'idProofs' ? null : 'idProofs'; 
  }

    showDialog(partner: any) {
        this.visible = true;
        this.selectedPartner = partner;
        this.toggleBasicDetails();
        this.activeTable === 'basic';
    }
    
    DontshowDialog() {
      this.visible = false;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.restaurantId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.restaurantId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');   
  }

}
