<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Discounts'"></app-title-bar>
    </div>
    <div class="bodySection">
        <div class="mb-3 d-flex justify-content-end">
            <span>
                <p-button label="Add Discount" icon="pi pi-plus" (onClick)="toggleAddDiscountModal()" />
            </span>
        </div>
        <p-table [value]="discounts" dataKey="id">
            <ng-template pTemplate="header">
                <tr>
                    <th>Discount Code</th>
                    <th>Discount Description</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-discount let-expanded="expanded">
                <tr>
                    <td>{{ discount.discountCode }}</td>
                    <td>{{ discount.discountDescription }}</td>
                    <!-- <td>{{ getFeatureNames(accessGroup.accessGroupList) }}</td>  -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        <div class="d-flex justify-content-center">
                            No discounts found
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog header="Add discount" class="signUpModal" [modal]="true" [style]="{ width: '400px' }"
    [(visible)]="showAddAccessRoleModal" [draggable]="false" [closable]="true">
    <!-- (onHide)="toggleAddAccessRoleModal(null, true)"> -->
    <form [formGroup]="addDiscountForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-100">
                    <label for="discountCode">Discount Code</label>
                    <input pInputText id="discountCode" formControlName="discountCode" />
                </div>
            </div>
            <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-100">
                    <label for="discountDescription" class="form-label">Discount Description</label>
                    <textarea rows="5" cols="30" pInputTextarea formControlName="discountDescription"
                        placeholder="Discount Description : A fresh blend of lime along with......"></textarea>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button [disabled]="addDiscountForm.invalid" label="Add"
            (onClick)="addDiscount()" />
    </div>
</p-dialog>