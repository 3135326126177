import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {

  activeDiscountsCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore) {
    this.activeDiscountsCollection = fireStore.collection('activeDiscounts');
  }

  getActiveDiscounts() {
    return this.activeDiscountsCollection.get()
  }

  addDiscounts(obj: any) {
    return this.activeDiscountsCollection.add(obj)
  }
}
