<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Staff Members'"></app-title-bar>
    </div>
    <div class="bodySection" *ngIf="staffData">
        <div class="mb-3 d-flex justify-content-end" *ngIf="activeAction == 'All' && staffData?.role == 'Super Admin'">
            <span>
                <p-button label="Add Staff" icon="pi pi-plus" (onClick)="toggleAddStaffModal()" />
            </span>
        </div>
        <p-table [value]="staffMembers" #table 
        [loading]="isTableLoading"
        [paginator]="true"  
        [rows]="5">
            <ng-template pTemplate="header">
                <tr>
                    <th>Staff Id</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-staff>
                <tr>
                    <td>{{ staff.staffId }}</td>
                    <td>{{ staff.firstName ? staff.firstName : '-' }} {{ staff.lastName ? staff.lastName : ''}}</td>
                    <td>{{ staff.email }}</td>
                    <td>{{ staff.mobile ? staff.mobile : '-' }}</td>
                    <td>{{ staff.role }}</td>
                    <td>
                        <span class="statusPill" [ngClass]="{'activePill': staff.isActive, 'rejectedPill': !staff.isActive}">
                            {{ staff.isActive ? 'Active' : 'Disabled' }}
                        </span>
                    </td>
                    <td>
                        <div class="d-flex gap-2">
                            <span 
                                *ngIf="showAnimationContainer && staffMembers" 
                                #editIconContainer
                                [attr.data-location-id]="staff.staffId"
                                class="d-flex animDeleteIcon" 
                                (click)="toggleAddStaffModal(staff)"
                            >
                            </span> 
                            <span 
                                *ngIf="showAnimationContainer && staffMembers && staffData.role == 'Super Admin'" 
                                #removeIconContainer
                                [attr.data-location-id]="staff.staffId"
                                class="d-flex animDeleteIcon" 
                                (click)="toggleDeleteLocationConfirmation(staff)"
                            >
                            </span> 
                        </div>
                    </td> 
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7" class="text-center">No staff members with this role. Please try any other role.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog header="Add Staff" class="signUpModal" [modal]="true" [(visible)]="showStaffModal" [draggable]="false"
    [closable]="true">
    <div class="mt-2">
        <span class="p-text-secondary d-flex align-items-center gap-2"><span class="pi pi-info-circle"></span> Please
            ensure role that you are assigning to the specific user</span>
    </div>
    <form [formGroup]="addStaffForm">
        <div class="d-flex flex-column gap-3 my-4">
            <div class="d-flex flex-column gap-2">
                <label for="email">Email</label>
                <input pInputText id="email" formControlName="email" />
            </div>
            <div class="d-flex flex-column gap-2">
                <label for="role">Role</label>
                <p-dropdown [options]="staffRoles" formControlName="role" appendTo="body"
                    placeholder="Select a Role"/>
            </div>
            <div class="d-flex flex-column gap-2" *ngIf="this.isUpdateStaff">
                <label for="isActive">
                    <input type="checkbox" id="isActive" formControlName="isActive" />
                    Is Active
                </label>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Continue" [disabled]="addStaffForm.invalid" (onClick)="registerNewStaff()" />
    </div>
</p-dialog>


<p-dialog header="Confirm" class="signUpModal" [modal]="true" [(visible)]="showDeleteConfirmationModal"
    [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete <strong>{{selectedStaff?.staffId}}</strong>.
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger" (onClick)="removeStaff()" />
    </div>
</p-dialog>