<p-toast></p-toast>
<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 vh-100 d-flex justify-content-center align-items-center">
    <div class="subContainer">
        <div class="leftPanel">
            <div class="d-flex flex-column h-100 gap-4 justify-content-center">
                <div class="headerSection d-flex justify-content-center">
                    {{!showResetForm ? 'Sign In' : 'Reset Password'}}
                </div>
                <form 
                    (ngSubmit)="!showResetForm ? staffLogin() : initReset()" 
                    [formGroup]="!showResetForm ? userLoginForm : userResetForm" 
                    class="d-flex flex-column gap-3">
                    <div class="mailSection d-flex justify-content-center">
                        <p-iconField iconPosition="right">
                            <p-inputIcon styleClass="pi pi-envelope"></p-inputIcon>
                            <input 
                                type="email" 
                                pInputText 
                                placeholder="Email" 
                                formControlName="email" 
                                required />
                        </p-iconField>
                    </div>
                    <div class="securitySection d-flex justify-content-center" *ngIf="!showResetForm">
                        <p-iconField iconPosition="right">
                            <input 
                                [type]="isPasswordVisible ? 'text' : 'password'" 
                                pInputText 
                                placeholder="Password" 
                                formControlName="password" 
                                required />
                            <i 
                                [ngClass]="isPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'" 
                                (click)="togglePasswordVisibility()"></i>
                        </p-iconField>
                    </div>
                    <div class="createSection d-flex justify-content-center">
                        <p-button 
                            *ngIf="!showResetForm" 
                            label="Forget Password?" 
                            [link]="true" 
                            (onClick)="toggleReset()"></p-button>
                        <p-button 
                            *ngIf="showResetForm" 
                            label="Back to sign in" 
                            [link]="true" 
                            (onClick)="toggleReset()"></p-button>
                    </div>
                    <div class="btnSection d-flex justify-content-center">
                        <p-button 
                            *ngIf="!showResetForm" 
                            label="SIGN IN" 
                            [rounded]="true" 
                            [disabled]="userLoginForm.invalid" 
                            type="submit">
                        </p-button>
                        <p-button 
                            *ngIf="showResetForm" 
                            label="Reset" 
                            [rounded]="true" 
                            [disabled]="userResetForm.invalid" 
                            type="submit">
                        </p-button>
                    </div>
                </form>
            </div>
        </div>
        <div class="rightPanel">
            <div class="d-flex flex-column gap-4 justify-content-center h-100">
                <div class="greetSection d-flex justify-content-center">
                    Welcome Back!
                </div>
                <div class="infoSection">
                    <div class="firstInfo d-flex justify-content-center">
                        Enter login credentials 
                    </div>
                    <div class="secondInfo d-flex justify-content-center">
                        to get into your Staff account
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
