import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, lastValueFrom, Observable, switchMap } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StaffService {

  staffCollection: AngularFirestoreCollection;
  staffDetails: any;

  constructor(private fireStore: AngularFirestore, private http: HttpClient, private fns: AngularFireFunctions) { 
    this.staffCollection = fireStore.collection('staff');
  }

  getAllStaffMembers() {
    return this.staffCollection.ref
      .where(firebase.firestore.FieldPath.documentId(), '!=', 'applications') // Exclude the 'applications' document
      .get();
  }

  sendEmail(to: string[], subject: string, htmlContent: string): Observable<any> {
    const body = {
      to,
      subject,
      html: htmlContent,
      cc: ['abhinav.chinta@adev.co.in', 'karish.md@adev.co.in']
    };

    console.warn(body);
    

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(environment.cloudFunctionURL+'/sendEmail', body, httpOptions);
  }

  createNewStaffMember(email: string, password: string) {
    const headers = { 'Content-Type': 'application/json' };
    const body = { email, password };

    return this.http.post(environment.cloudFunctionURL+'/createNewStaffMember', body, { headers }).toPromise();
  }

  addStaff(uid: string, obj: any) {
    return this.staffCollection.doc(uid).set(obj, { merge: true });
  }

  completeStaffRegistration(uid: string, obj: any) {
    const data = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobile: obj.mobile,
    }
    return this.staffCollection.doc(uid).set(data, {merge: true});
  }

  clearNotification(notificationId: any) {
    return this.staffCollection.doc(this.staffDetails.uid).collection('notifications').doc(notificationId).delete();
  }
  
  async removeStaff(uid: string): Promise<void> {
    try {
      // Call the Cloud Function via its HTTPS endpoint
      const result = await lastValueFrom(
        this.http.post<{ success?: boolean; error?: string }>(environment.cloudFunctionURL+'/deleteUser', { uid }).pipe(
          catchError((error) => {
            console.error('Error calling deleteUser endpoint:', error);
            throw new Error('Failed to delete user via Cloud Function.');
          })
        )
      );

      if (result?.success) {
        // On success, delete the user document from Firestore
        await this.staffCollection.doc(uid).delete();
        console.log(`User with UID ${uid} removed from Firestore.`);
      } else {
        // Handle the error returned by the Cloud Function
        console.error(`Cloud Function error: ${result?.error}`);
        throw new Error(result?.error || 'Failed to delete user.');
      }
    } catch (error) {
      console.error('Error removing staff:', error);
      throw error; // Rethrow the error for further handling
    }
  }
}
