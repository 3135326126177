import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { GlobalDataService } from '../../services/global-data.service';
import firebase from 'firebase/compat/app';
import { NavItemsService } from '../../services/nav-items.service';
import { ToastService } from '../../shared/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-items',
  templateUrl: './nav-items.component.html',
  styleUrl: './nav-items.component.scss'
})
export class NavItemsComponent {

  showSpinner: boolean = false;
  showAddFeatureModal: boolean = false;
  showAddSubFeatureModal: boolean = false;
  showDeleteFeatureModal: boolean = false;
  showDeleteSubFeatureModal: boolean = false;
  features: any[] = [];
  addFeatureForm: FormGroup;
  addSubFeatureForm: FormGroup;
  user: any;
  userData: any;
  selectedFeature: any;
  selectedSubFeature: any;
  isUpdateFeature: boolean = false;
  isUpdateSubFeature: boolean = false;

  constructor(private fb: FormBuilder, private globalDataService: GlobalDataService, private authService: AuthService,
    private navItemsService: NavItemsService, private toastService: ToastService, private router: Router
  ) {
    this.addFeatureForm = this.fb.group({
      featureName: ['', Validators.required],
      featureIcon: ['', Validators.required],
      isActive: [false, Validators.required],
      isExpandable: [false, Validators.required],
    });
    this.addSubFeatureForm = this.fb.group({
      subFeatureName: ['', Validators.required],
      isActive: [false, Validators.required],
    });
    if(this.globalDataService.checkAccess('Nav Items')) {
      this.getFeatureList();
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.user = user;
          this.userData = this.globalDataService.getUserData();
          if (!this.userData) {
            this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
              this.userData = res.data();
              this.globalDataService.setUserData(this.userData);
            })
          }
        } else {
          console.warn("User not logged in");
          // User not logged in or has just logged out.
        }
      });
    } else {
      this.router.navigate(['/home']);
      this.toastService.showError("You are not authorised to access this feature")
    }
  }

  getFeatureList() {
    this.showSpinner = true;
    this.features = [];
    this.navItemsService.getNavItems().subscribe((res: any) => {
      res.forEach((navItem: any) => {
        this.features.push(navItem);
      });
      this.features.sort((a, b) => {
        const nameA = a.featureName?.toLowerCase();
        const nameB = b.featureName?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; 
      });
      this.showSpinner = false;
    });
  }
  
  toggleAddFeatureModal(feature?: any, isClose?: any) {
    if (isClose) {
      this.showAddFeatureModal = false;
      this.addFeatureForm.reset();
    } else {
      this.showAddFeatureModal = !this.showAddFeatureModal;
    }
    if (!this.showAddFeatureModal) {
      this.addFeatureForm.reset()
      this.isUpdateFeature = false;
      this.selectedFeature = null;
    } else {
      if (feature) {
        this.isUpdateFeature = true;
        this.selectedFeature = feature;
        this.addFeatureForm.patchValue({
          featureName: feature.featureName,
          featureIcon: feature.featureIcon,
          isActive: feature.isActive,
          isExpandable: feature.isExpandable,
        });
      } else {
        this.isUpdateFeature = false;
        this.selectedFeature = null;
      }
    }
  }

  toggleAddSubFeatureModal(feature?: any, subFeature?: any, isClose?: any) {
    if(isClose) {
      this.showAddSubFeatureModal = false;
      this.addSubFeatureForm.reset();
      this.selectedSubFeature = null
    } else {
      this.showAddSubFeatureModal = !this.showAddSubFeatureModal;
      if(this.showAddSubFeatureModal) {
        this.selectedFeature = feature;
        if(subFeature) {
          this.isUpdateSubFeature = true;
          this.selectedSubFeature = subFeature
          this.addSubFeatureForm.patchValue({
            subFeatureName: subFeature.subFeatureName,
            isActive: subFeature.isActive,
          });
        }
      } else {
        this.selectedFeature = null;
      }
    }
  }

  toggleDeleteFeatureModal(feature: any) {
    this.showDeleteFeatureModal = !this.showDeleteFeatureModal
    this.selectedFeature = feature
  }

  addNewFeature() {
    this.showSpinner = true;
    var obj = {}
    if(this.isUpdateFeature) {
      obj = {
        featureName: this.addFeatureForm.value.featureName,
        featureIcon: this.addFeatureForm.value.featureIcon,
        isActive: this.addFeatureForm.value.isActive,
        isExpandable: this.addFeatureForm.value.isExpandable,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
      }
      this.navItemsService.updateNavItem(this.selectedFeature.id, obj).then((res: any) => {
        this.toastService.showSuccess("Feature updated successfully")
        this.showAddFeatureModal = false;
        this.getFeatureList();
        this.showSpinner = false;
      }, err => {
        this.toastService.showError(err)
        this.showAddFeatureModal = false;
        this.showSpinner = false;
      })
    } else {
      obj = {
        featureName: this.addFeatureForm.value.featureName,
        featureIcon: this.addFeatureForm.value.featureIcon,
        isActive: this.addFeatureForm.value.isActive,
        isExpandable: this.addFeatureForm.value.isExpandable,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName,
      };
      this.navItemsService.addNewNavItem(obj).then((res: any) => {
        this.toastService.showSuccess("Feature added successfully")
        this.showAddFeatureModal = false;
        this.getFeatureList();
        this.showSpinner = false;
      }, err => {
        this.toastService.showError(err)
        this.showAddFeatureModal = false;
        this.showSpinner = false;
      })
    }
  }

  addnewSubFeature() {
    this.showSpinner = true;
    var obj = {}
    if(this.isUpdateSubFeature) {
      obj = {
        subFeatureName: this.addSubFeatureForm.value.subFeatureName,
        isActive: this.addSubFeatureForm.value.isActive,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
      };
      this.navItemsService.updateSubFeature(this.selectedFeature, this.selectedSubFeature.subFeatureName, obj).then((res: any) => {
        this.toastService.showSuccess("Sub feature updated successfully");
        this.showAddSubFeatureModal = false;
        this.isUpdateSubFeature = false;
        this.selectedFeature = null;
        this.selectedSubFeature = null;
        this.showSpinner = false;
        this.getFeatureList()
      }, err => {
        this.toastService.showError(err);
        this.showAddSubFeatureModal = false;
        this.selectedFeature = null;
        this.selectedSubFeature = null;
        this.showSpinner = false;
        this.isUpdateSubFeature = false;
      })
    } else {
      obj = {
        subFeatureName: this.addSubFeatureForm.value.subFeatureName,
        isActive: this.addSubFeatureForm.value.isActive,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName,
      };
      this.navItemsService.addNewSubFeature(this.selectedFeature.id, [obj]).then((res: any) => {
        this.toastService.showSuccess("Sub Feature added successfully");
        this.showAddSubFeatureModal = false;
        this.showSpinner = false;
        this.getFeatureList();
      }, err => {
        this.toastService.showError(err);
        this.showAddSubFeatureModal = false;
        this.showSpinner = false;
        this.getFeatureList();
      })
    }
  }

  deleteFeature() {
    this.showSpinner = true;
    this.navItemsService.deleteFeature(this.selectedFeature.id).then((res: any) => {
      this.toastService.showSuccess("Feature deleted successfully")
      this.showDeleteFeatureModal = false;
      this.getFeatureList();
      this.selectedFeature = null;
      this.showSpinner = false;
    }, err => {
      this.toastService.showError(err)
      this.showDeleteFeatureModal = false;
      this.showSpinner = false;
      this.selectedFeature = null;
    })
  }

  toggleDeletesubFeatureConfirmation(feature: any, subFeature: any) {
    this.showDeleteSubFeatureModal = !this.showDeleteSubFeatureModal;
    if (this.showDeleteSubFeatureModal) {
      this.selectedFeature = feature;
      this.selectedSubFeature = subFeature;
    } else {
      this.selectedFeature = null;
    }
  }

  deleteSubFeature(feature: any, subFeatureName: string) {
    this.showSpinner = true;
    const subFeatureToDelete = this.features.find(feature => feature.featureName === this.selectedFeature.featureName);
    if (subFeatureToDelete) {
      const subFeatureIndex = subFeatureToDelete.subFeatures.findIndex((subFeature: any) => subFeature.subFeatureName === subFeatureName);
      if (subFeatureIndex > -1) {
        subFeatureToDelete.subFeatures.splice(subFeatureIndex, 1); // Remove the locality from the array
        this.navItemsService.deleteSubFeature(feature.id, { subFeatures: subFeatureToDelete.subFeatures })
          .then(() => {
            this.toastService.showSuccess('Locality deleted successfully');
            this.getFeatureList(); // Refresh the locations data
            this.showDeleteSubFeatureModal = false;
            this.showSpinner = false
            this.showSpinner = false
          })
          .catch((error: any) => {
            this.showSpinner = false
            console.error('Error deleting locality:', error);
            this.toastService.showError('Error deleting locality');
          });
          
        } else {
        this.showSpinner = false
        console.error("Sub feature not found with name:", subFeatureName, "within feature:", feature.featureName);
      }
    } else {
      this.showSpinner = false
      console.error("Feature not found with ID:", feature.id);
    }
  }

}
