import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../services/global-data.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavItemsService } from '../../services/nav-items.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {

  activeIndices: { [key: string]: boolean } = {}; // Store active indices using navItem.name as key
  navItems: any[] = [];
  accessGroupList: any[] = [];
  @Input() userData: any;
  @ViewChild('profileOverlayPanel') profileOverlayPanel!: OverlayPanel;
  activeIndex: number | null = null;
  activeIndicesArr: number[] = []; 

  constructor(private authService: AuthService, private router: Router, private globalDataService: GlobalDataService, private firebaseAuth: AngularFireAuth,
    private changeDetectorRef: ChangeDetectorRef, private navItemsService: NavItemsService
  ) {
    this.navItems = [];
    var roleTimer = setInterval(() => {
      if(this.userData) {
        clearInterval(roleTimer)
        this.navItemsService.getAccessData(this.userData.role).subscribe((res: any) => {
          this.accessGroupList = res[0].accessGroupList
          localStorage.setItem('accessGroupList', JSON.stringify(this.accessGroupList))
          this.getFeatureList();
        })
      }
    },);
  }

  getFeatureList() {
    this.navItems = [];
    this.navItemsService.getNavItems().subscribe((res: any) => {
      res.forEach((navItem: any) => {
        // Check if the featureName exists in accessGroupList
        const isNavItemAllowed = this.accessGroupList.some(
          (accessItem) => accessItem.featureName === navItem.featureName
        );

        if (isNavItemAllowed && navItem.isActive) { // Check isActive only if allowed
          if (navItem.isExpandable) {
            navItem.subNavItems = navItem.subFeatures?.filter(
              (subNavItem: any) => subNavItem.isActive
            );
            if (!navItem.subNavItems) {
              navItem.subNavItems = [];
            }
            delete navItem.subFeatures;
          }
          this.navItems.push(navItem);
        }
      });
    });
  }

  ngOnInit() {
    this.activeIndex = this.checkAccordion(); 
  }

  onTabOpen(navItemName: string) {
    this.activeIndices[navItemName] = true;
  }

  onTabClose(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  onClick(navItemName: string) {
    this.activeIndices[navItemName] = false;
  }

  navigateToHome() {

  }

  updateRoute(mainRoute: string, subRoute?: string) {
    this.globalDataService.setUserData(this.userData);
    if(subRoute) {
      this.router.navigate(['home', mainRoute.toLowerCase()], {
        queryParams: { action: subRoute?.toLowerCase() }
      });
    } else {
      this.router.navigate(['home', this.camelCaseString(mainRoute.toLowerCase())])
    }
  }

  camelCaseString(str: string): string {
    return str.replace(/\s+([a-z])/g, (match, letter) => letter.toUpperCase())
              .replace(/\s+/g, '');
  }

  logout() {
    this.profileOverlayPanel.hide();
    this.firebaseAuth.signOut();
    this.router.navigateByUrl('/login')
  }

  alignOverlayPanel(event: any) {
    setTimeout(() => {
      var ele = this.profileOverlayPanel.container as HTMLElement;
      var leftValue = parseFloat(ele.style.left);
      var topValue = parseFloat(ele.style.top);
      var profileEle = document.getElementsByClassName('profileSection')[0] as HTMLElement
      leftValue = leftValue + profileEle.offsetWidth + profileEle.offsetLeft * 2
      topValue = topValue + profileEle.offsetHeight
      ele.style.left = `${leftValue}px`
      ele.style.top = `${topValue}px`
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  checkAccordion() {
    const currentUrl = window.location.href;
    var returnVal = 0;
    this.navItems.forEach((item, index) => {
      if (currentUrl.includes(item.name.toLowerCase())) {
        returnVal = index
      }
    });
    return returnVal;
  }

  isNavItemActive(navItem: string) {
    const currentUrl = window.location.href;
    if(currentUrl.includes('home/'+this.camelCaseString(navItem.toLowerCase()))) {
      return true;
    } else {
      return false;
    }
  }

  isSubNavItemActive(navItem: string, subNavItem: string) {
    const currentUrl = window.location.href;
    if(currentUrl.includes(navItem?.toLowerCase()) && currentUrl.includes(subNavItem.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  preventExpansion(event: any, isExpandable: boolean, navItemName: string) {
    if (!isExpandable) {
      event.stopPropagation();
      this.updateRoute(navItemName);
    }
  }

  toggleAccordion(index: number) {
    this.activeIndices[index] = !this.activeIndices[index];
  }

  isAccordionExpanded(index: number): boolean {
    return !!this.activeIndices[index]; // Returns true if expanded, false otherwise
  }


onTabChange(event: any): void {
  this.activeIndex = event;
}

}
