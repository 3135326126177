<div class="titleSection d-flex justify-content-center">
    <div class="d-flex align-items-baseline brandLogo" style="gap: 3px;" (click)="navigateToHome()">
        <span class="aahaarTitleOblong">AA</span>
        <span class="aahaarTitle">haar</span>
    </div>
</div>
<div class="navContainer">
    <p-accordion class="navAccordion" [multiple]="false" [activeIndex]="activeIndex" (activeIndexChange)="onTabChange($event)">
        <p-accordionTab class="navItem" *ngFor="let navItem of navItems; let i = index">
          <ng-template pTemplate="header">
            <div class="navItemHeader d-flex w-100 justify-content-between align-items-center"
              [ngClass]="{ 'activeNavItem': isNavItemActive(navItem.featureName) }"
             (click)="preventExpansion($event, navItem.isExpandable, navItem.featureName)">
              <div class="d-flex align-items-center">
                <span [class]="navItem.featureIcon + ' navItemIcon'"></span>
                <span class="navItemTitle">
                  {{ navItem.featureName }}
                </span>
              </div>
              <i class="pi pi-chevron-down expandIcon" *ngIf="navItem.isExpandable"
                  [ngClass]="{'rotate': i == activeIndex}"></i>
            </div>
          </ng-template>
          <!-- Only show body if navItem.isExpandable is true -->
          <div class="expandedNavBody d-flex gap-2 flex-wrap" *ngIf="navItem.isExpandable && navItem.subNavItems?.length > 0">
            <div 
              class="subNavItem" 
              *ngFor="let subNavItem of navItem.subNavItems" 
              (click)="updateRoute(navItem?.featureName, subNavItem.subFeatureName)"
              [ngClass]="{ 'activeSubNavItem': isSubNavItemActive(navItem?.name, subNavItem) }" 
            >
              {{subNavItem.subFeatureName}}
            </div>
          </div>
          <div class="expandedNavBody d-flex gap-2 flex-wrap" *ngIf="navItem.isExpandable && navItem.subNavItems?.length == 0">
              No sub features found
          </div>
        </p-accordionTab>
      </p-accordion>
</div>
<div class="profileSection" (click)="profileOverlayPanel.toggle($event)">
    <div class="d-flex align-items-center justify-content-between w-100 gap-2">
        <div class="profileIcon">
            <span class="profileInitials">{{ userData?.firstName?.charAt(0) }}{{ userData?.lastName?.charAt(0) }}</span>
        </div>
        <div class="ml-2 d-flex flex-column profileName">
            <span class="userInfo">{{ userData?.firstName }} {{ userData?.lastName }}</span>
            <span class="text-muted userEmail">{{ userData?.email }}</span>
        </div>
    </div>
    <p-overlayPanel #profileOverlayPanel [style]="{width: '200px'}" appendTo="body"
        (onShow)="alignOverlayPanel($event)">
        <ng-template pTemplate="content">
            <div class="profileOptions">
                <div class="optionItem d-flex justify-content-between align-items-center" (click)="logout()">
                    <span>Sign Out</span>
                    <span class="pi pi-sign-out"></span>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>