<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Nav Items'"></app-title-bar>
    </div>
    <div class="bodySection">
        <div class="mb-3 d-flex justify-content-end">
            <span>
                <p-button label="Add Feature" icon="pi pi-plus" (onClick)="toggleAddFeatureModal()" />
            </span>
        </div>
        <p-table [value]="features" dataKey="id">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 2rem"></th>
                    <th colspan="1">Name</th>
                    <th>Icon</th>
                    <th>Is Active</th>
                    <th>Is Expandable</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-feature let-expanded="expanded">
                <tr>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="feature"
                        [disabled]="!feature.isExpandable"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : (feature.isExpandable ? 'pi pi-chevron-right' : '')">
                        </button>
                    </td>
                    <td colspan="1">{{ feature.featureName }}</td>
                    <td>
                        <span [classList]="feature.featureIcon"></span>
                    </td>
                    <td>
                        <span class="statusPill"
                            [ngClass]="{'activePill': feature.isActive, 'rejectedPill': !feature.isActive}">
                            {{ feature.isActive }}
                        </span>
                    </td>
                    <td>
                        <span class="statusPill"
                            [ngClass]="{'activePill': feature.isExpandable, 'rejectedPill': !feature.isExpandable}">
                            {{ feature.isExpandable }}
                        </span>
                    </td>
                    <td>
                        <div class="d-flex gap-2">
                            <span *ngIf="feature.isExpandable" class="pi pi-plus actionIconBtn"
                                (click)="toggleAddSubFeatureModal(feature)"></span>
                            <span class="pi pi-pen-to-square actionIconBtn"
                                (click)="toggleAddFeatureModal(feature)"></span>
                            <span class="pi pi-trash actionIconBtn" (click)="toggleDeleteFeatureModal(feature)"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-feature>
                <tr>
                  <td colspan="7">
                    <div class="p-3">
                      <p-table [value]="feature.subFeatures" dataKey="id">  
                        <ng-template pTemplate="header">
                          <tr>
                            <th>Name</th>
                            <th>Is Active</th>
                            <th>Actions</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-subFeature>
                          <tr>
                            <td>{{ subFeature.subFeatureName }}</td>
                            <td>
                              <span class="statusPill" 
                                    [ngClass]="{'activePill': subFeature.isActive, 'rejectedPill': !subFeature.isActive}">
                                {{ subFeature.isActive }}
                              </span>
                            </td>
                            <td>
                              <div class="d-flex gap-2">
                                <span class="pi pi-pen-to-square actionIconBtn" (click)="toggleAddSubFeatureModal(feature, subFeature)"></span>
                                <span class="pi pi-trash actionIconBtn" (click)="toggleDeletesubFeatureConfirmation(feature, subFeature)"></span>
                              </div>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="3">
                              <div class="d-flex justify-content-center">
                                No sub features found for this feature
                              </div>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </td>
                </tr>
              </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        <div class="d-flex justify-content-center">
                            No features found
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [header]="isUpdateFeature ? 'Update Feature': 'Add Feature'" class="signUpModal" [modal]="true"
    [(visible)]="showAddFeatureModal" [draggable]="false" [closable]="true"
    (onHide)="toggleAddFeatureModal(null, true)">
    <form [formGroup]="addFeatureForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2">
                    <label for="featureName">Feature Name</label>
                    <input pInputText id="featureName" formControlName="featureName" />
                </div>
                <div class="d-flex flex-column gap-2">
                    <label for="featureIcon">Feature Icon</label>
                    <input pInputText id="featureIcon" formControlName="featureIcon" />
                </div>
            </div>
            <div class="d-flex justify-content-start gap-3 mt-4">
                <div class="form-check w-50">
                    <input class="form-check-input" type="checkbox" value="" formControlName="isActive"
                        id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Is Active
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" formControlName="isExpandable"
                        id="flexCheckChecked">
                    <label class="form-check-label" for="flexCheckChecked">
                        Is Expandable
                    </label>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button [label]="isUpdateFeature ? 'Update' : 'Add'" [disabled]="addFeatureForm.invalid"
            (onClick)="addNewFeature()" />
    </div>
</p-dialog>
<p-dialog  [header]="isUpdateSubFeature ? 'Update sub feature': 'Add sub feature'" class="signUpModal" [modal]="true"  [style]="{ width: '400px' }"
    [(visible)]="showAddSubFeatureModal" [draggable]="false" [closable]="true"
    (onHide)="toggleAddSubFeatureModal(null, null, true)">
    <form [formGroup]="addSubFeatureForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-100">
                    <label for="subFeatureName">Sub Feature Name</label>
                    <input class="w-100" pInputText id="subFeatureName" formControlName="subFeatureName" />
                </div>
            </div>
            <div class="d-flex justify-content-start gap-3 mt-2">
                <div class="form-check w-50">
                    <input class="form-check-input" type="checkbox" value="" formControlName="isActive"
                        id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Is Active
                    </label>
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Add" [disabled]="addSubFeatureForm.invalid" [label]="isUpdateSubFeature ? 'Update' : 'Add'"
            (onClick)="addnewSubFeature()" />
    </div>
</p-dialog>
<p-dialog header="Confirm" class="signUpModal" [modal]="true" [(visible)]="showDeleteFeatureModal" [draggable]="false"
    [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete <strong>{{selectedFeature?.featureName}}</strong>.
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger" (onClick)="deleteFeature()" />
    </div>
</p-dialog>
<p-dialog header="Confirm" class="signUpModal" [modal]="true" [(visible)]="showDeleteSubFeatureModal" [draggable]="false"
    [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete <strong>{{selectedSubFeature?.subFeatureName}}</strong> sub feature from <strong>{{selectedFeature?.featureName}}</strong>.
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger" (onClick)="deleteSubFeature(selectedFeature, selectedSubFeature.subFeatureName)" />
    </div>
</p-dialog>